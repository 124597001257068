var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_amortization_list") } },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            "data-testid": "amortization-list-filter-form"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("SelectBranch", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-branch"
                        },
                        model: {
                          value: _vm.filters.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "branch", $$v)
                          },
                          expression: "filters.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_lessor_name") } },
                    [
                      _c("SelectLessor", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-lessor"
                        },
                        model: {
                          value: _vm.filters.lessor,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "lessor", $$v)
                          },
                          expression: "filters.lessor"
                        }
                      })
                    ],
                    1
                  ),
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_transaction_type") }
                  }),
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_amortization_number") }
                  }),
                  _c("a-form-model-item", {
                    attrs: { label: _vm.$t("lbl_status") }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_document_number") } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t("lbl_document_number"),
                          "data-testid":
                            "amortization-list-filter-document-number"
                        },
                        model: {
                          value: _vm.filters.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "documentNumber", $$v)
                          },
                          expression: "filters.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_leasing_number") } },
                    [
                      _c("SelectLeasing", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid": "amortization-list-filter-leasing"
                        },
                        on: { "update:meta": _vm.handleChangeLeasing },
                        model: {
                          value: _vm.filters.leasingNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "leasingNumber", $$v)
                          },
                          expression: "filters.leasingNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_leasing_contract_number") } },
                    [
                      _c("SelectLeasing", {
                        attrs: {
                          "label-in-value": "",
                          "data-testid":
                            "amortization-list-filter-contract-number",
                          accessor: "leasingContractNumber"
                        },
                        on: { "update:meta": _vm.handleChangeLeasing },
                        model: {
                          value: _vm.filters.leasingContractNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "leasingContractNumber", $$v)
                          },
                          expression: "filters.leasingContractNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_period") } },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "data-testid": "amortization-list-filter-period"
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid":
                                      "amortization-list-filter-btn-reset"
                                  },
                                  on: { click: _vm.handleResetFilter }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "data-testid":
                                      "amortization-list-filter-btn-find"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12, align: "end" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                "data-testid":
                                  "amortization-list-filter-btn-create-new"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      columns: _vm.columns,
                      "data-source": [],
                      scroll: { x: 1200 },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "amortization-list-table"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "amortization-list-btn-download",
                        type: "primary"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_download")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }