

















































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import SelectLeasing from "@/components/Leasing/SelectLeasing.vue";
import { Option } from "@/models/class/option.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ListAmortizationFilter } from "@/models/interface/amortization";
import { ListLeasingHeaderDto } from "@/models/interface/leasing";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SelectBranch,
    SelectLessor,
    SelectLeasing,
  },
})
export default class Amortizations extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  filters: ListAmortizationFilter = this.initDefaultFilters();

  columns = [
    {
      title: this.$t("lbl_amortization_number"),
      dataIndex: "amortizationNumber",
      key: "amortizationNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_period"),
      dataIndex: "period",
      key: "period",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branch",
      key: "branch",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_lessor_name"),
      dataIndex: "lessorName",
      key: "lessorName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_leasing_number"),
      dataIndex: "leasingNumber",
      key: "leasingNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      key: "leasingContractNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_cash_out_number"),
      dataIndex: "cashOutNumber",
      key: "cashOutNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_total_amount"),
      dataIndex: "totalAmount",
      key: "totalAmount",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "action",
      key: "action",
      customRender: text => text || "-",
    },
  ];

  initDefaultFilters(): ListAmortizationFilter {
    return {
      amortization: undefined,
      branch: undefined,
      documentNumber: undefined,
      leasingContractNumber: undefined,
      leasingNumber: undefined,
      lessor: undefined,
      period: null,
      status: undefined,
      transactionType: undefined,
    };
  }

  handleResetFilter(): void {
    this.filters = this.initDefaultFilters();
  }

  handleChangeLeasing(leasing: Option<ListLeasingHeaderDto> | undefined): void {
    const value = leasing?.meta;
    if (!value) {
      return;
    }

    this.filters.leasingNumber = {
      key: value.id,
      label: value.leasingNumber,
    };
    this.filters.leasingContractNumber = {
      key: value.id,
      label: value.leasingContractNumber,
    };
  }
}
